<template>
    <section class="main-wrap" v-if="isShowLogin">
        <div class="intro-box-outer">
            <div class="intro-box" v-bind:style="{ 'background-image': backgroundImage }">
                <div class="intro-header"
                    v-if="getLoginScreenObj.logo_status == 1 && getLoginScreenObj.main_logo != undefined && getLoginScreenObj.main_logo != null && getLoginScreenObj.main_logo != ''">
                    <a href="#"><img :src="'https://app.saasonboard.com/assets/img/login_screen_logo/' + getLoginScreenObj.main_logo"
                            style="max-height:43px;max-weight:90px"></a>
                </div>
                <div class="intro-main-wrap" style="color: #fff !important;">
                    <p class="login-title"
                        v-if="getLoginScreenObj.headline_text_status == 1 && getLoginScreenObj.title != undefined && getLoginScreenObj.title != null && getLoginScreenObj.title != ''"
                        v-html="getLoginScreenObj.title"></p>

                    <div class="pb-2" v-if="getLoginScreenObj.headline_image_status == 1 && getLoginScreenObj.headline_image != null">
                        <img :src="'https://app.saasonboard.com/assets/img/login_screen_background/' + getLoginScreenObj.headline_image"
                            style="height:255px; width: 100%; object-fit: contain;">
                    </div>
                    <iframe v-if="getLoginScreenObj.video_url != '' && getLoginScreenObj.video_url != null && getLoginScreenObj.video_provider == 1"
                        width="100%" style="max-width: 700px; aspect-ratio: 7/3.8;" :src="'//www.youtube.com/embed/' + getLoginScreenObj.video_url + '?rel=0'"
                        frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                    <iframe v-else-if="getLoginScreenObj.video_url != '' && getLoginScreenObj.video_url != null && getLoginScreenObj.video_provider == 2"
                        width="100%" style="max-width: 700px; aspect-ratio: 7/3.8;" :src="'//player.vimeo.com/video/' + getLoginScreenObj.video_url" frameborder='0'
                        webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                    <iframe v-else-if="getLoginScreenObj.video_url != '' && getLoginScreenObj.video_url != null && getLoginScreenObj.video_provider == 3"
                        width="100%" style="max-width: 700px; aspect-ratio: 7/3.8;"
                        :src="'//videogeyser.com/player/' + getLoginScreenObj.video_url + '/?VideoControls=true&AutoPlayVideo=false&FullScreenAllowed=true'"
                        allowtransparency="true" frameborder="0" scrolling="auto" allowfullscreen mozallowfullscreen
                        webkitallowfullscreen oallowfullscreen msallowfullscreen></iframe>
                    <div class="click-here-btn"
                        v-if="getLoginScreenObj.button_text != undefined && getLoginScreenObj.button_text != null && getLoginScreenObj.button_text != ''">
                        <a @mouseover="btnMouseOver(getLoginScreenObj)" @mouseout="btnMouseOut(getLoginScreenObj)" @click="addClicks()"
                            v-bind:style="{ color: getLoginScreenObj.button_text_color, background: btnBackColor != '' ? btnBackColor : getLoginScreenObj.button_color }" target="_blank"
                            :href="getLoginScreenObj.button_url">{{ getLoginScreenObj.button_text }}</a>
                    </div>
                    <div class="win-btn">
                        <a v-if="getLoginScreenObj.table_description != undefined && getLoginScreenObj.table_description != null && getLoginScreenObj.table_description != ''"
                            v-html="getLoginScreenObj.table_description"></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script type="text/javascript">
import { mapGetters, mapActions } from "vuex";
export default {
    // props: ['screen', 'screen_id', 'type' login_Screen],
    // props: {screen:Object,screen_id: Number,type:String},
    name: "LoginScreen",
    setup() {
        return {};
    },
    data() {
        return {
            btnBackColor: '',
            screen: null,
            isShowLogin : false
        }
    },
    computed: {
        ...mapGetters({
            getLoginScreenObj : 'SOB/getLoginScreenObj',
            getScreenType: 'SOB/getScreenType',
            getScreenId: 'SOB/getScreenId',
        }),
        backgroundImage() {
            console.log(this.getLoginScreenObj);
            if (this.getLoginScreenObj.main_backgound_image) {
                return `url(https://app.saasonboard.com/assets/img/login_screen_background/${this.getLoginScreenObj.main_backgound_image})`
            } else if (this.getLoginScreenObj.main_backgorund_image) {
                return `url(https://app.saasonboard.com/assets/img/login_screen_background/${this.getLoginScreenObj.main_backgorund_image})`
            } else {
                return 'url(https://app.saasonboard.com/assets/img/bg-image.png)'
            }
        }
    },
    mounted() {
        this.getIntroScreenAction()
    },
    methods: {
        ...mapActions({
            getIntroScreen: "SOB/getIntroScreen",
            addloginImpressions: "SOB/addloginImpressions",
            addLoginClick: "SOB/addLoginClick",
        }),
        btnMouseOver(data) {
            this.btnBackColor = data.button_mouseover_color
        },
        btnMouseOut(data) {
            this.btnBackColor = ''
        },
        addClicks(data) {
            let payload = {
                screen_id: this.getScreenId,
                type: this.getScreenType
            }
            this.addLoginClick(payload)
        },
        getIntroScreenAction() {
            this.getIntroScreen()
            .then(
                (res) => {
                    if (res.data.status && res.data.data) {
                        let payload = {
                            screen_id: this.getScreenId,
                            type: this.getScreenType
                        }
                        this.addloginImpressions(payload)
                        this.$emit('showSoblogin', true)
                        this.isShowLogin = true
                    }else{
                        this.$emit('showSoblogin', false)
                        this.isShowLogin = false
                    }
                }
            )
            .catch(
                (error) => {
                    console.log(error);
                    this.$emit('showSoblogin', false)
                    this.isShowLogin = false
                }
            )
        }
    },
}
</script>


<style scoped>
/* h1, h2, h3, h4, h5, h6{
  color:#fff !important;
} */
.intro-box {
    /*background-image: url(/assets/img/bg-image.png);*/
}

* {
    font-family: 'Poppins';
}

span {
    display: inline-block;
}

a {
    display: inline-block;
    text-decoration: none;
    font-size: 16px;
}

.intro-box-outer {
    /* max-width: 960px; */
    margin: 0 auto;
}

body {}

.intro-box {
    min-height: 100vh;
    max-height: 100vh;
    overflow: auto;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    background-size: 100% 100%;
}

.intro-box {
    text-align: center;
    padding: 32px 60px 90px 60px;
}

.intro-header {
    text-align: left;
}

.intro-main-wrap h1 {
    font-size: 44px;
    font-weight: bold;
}

.intro-main-wrap {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center
}

.intro-main-wrap p {
    color: #E6E6E6 !important;
    font-size: 22px;
    font-weight: 200;
    margin: 0 0 30px 0;
}

/* 
h1 {
    color: #E6E6E6 !important;
} */

.intro-main-wrap iframe {
    border-radius: 15px;
}

.click-here-btn a {
    border-radius: 5px;
    background: #00A3F5;
    color: #fff;
    font-size: 24px;
    padding: 21px 89px;
    margin-top: 30px;
}

.click-here-btn a:hover {
    text-decoration: none;
}

.win-btn a {
    color: #00A3F5 !important;
    text-decoration: underline !important;
    margin-top: 20px;
}



@media only screen and (max-width: 815px) {

    .intro-main-wrap {
        padding-top: 40px;
    }

    .intro-main-wrap h1 {
        font-size: 30px;
    }

    .intro-main-wrap p {
        font-size: 18px;
    }

    .intro-box {
        padding: 32px 30px 32px 30px;
    }

    .intro-main-wrap iframe {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .click-here-btn a {
        padding: 16px 40px;
        font-size: 18px;
    }
}

@media only screen and (max-width: 575px) {
    .intro-main-wrap h1 {
        font-size: 24px;
    }

    .click-here-btn a {
        padding: 15px 15px;
        font-size: 14px;
    }

    .win-btn a {
        font-size: 14px;
    }
}
</style>